import { useEffect, useState, lazy, Suspense } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { Toaster } from 'react-hot-toast'
import loadInitialSettings from './setup/loadInitialSettings'
import { createGraphQLClient } from './setup/graphql'
import { CartProvider } from './hooks/useCart'
import AppRoutes from './Routes'
import './setup/i18n'
import {
  setCampain,
  initializeTagManager,
} from './setup/tracking/trackingFunctions'
import { OneTrustButton } from './util/oneTrust'
import { AssetLoader } from './modules/assets'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { updateSrpInternalRoute } from './util/internalRoutes'
import useAppState from './hooks/useAppState'
import { InteractionStudioSalesforce } from './util/interactionStudioSalesforce'
import { loadCurrencyConfig } from './util/currencyHelper'
import { requestPermission } from './util/trackingTransparency'
import { EMPTY_STRING, MENU_MODAL_OPEN, PAGE_EVENTS, PORTAL_STYLE_SHEET_ID, eventKeys } from './util/constants'
import { useEventSubscriber } from 'use-event-emitter-hook'
import { cleanInvalidRequests } from './util/fetchRequestWithCache'
import { TRACKING_TRANSPARENCY_STATUS } from './util/constants'
import { appendStylesOverrideStyleSheet } from './util/cssStyleHelper'
import { FirebaseMessagingProvider } from './modules/firebase-messaging'

const LanguageModal = lazy(() => import('./popups/LanguageModal'))

export function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  const [graphQLClient, setGraphQLClient] = useState()
  const [trackingTransparency, setTrackingTransparency] = useState(false)
  const { setToken } = useAppState()
  const [modalOpen, setModalOpen] = useState(false)
  const { modalEventKey } = eventKeys
  const { t } = useTranslation('ui')

  useEventSubscriber(modalEventKey, (modalOpen) => {
    setModalOpen(modalOpen ? MENU_MODAL_OPEN : EMPTY_STRING)
  })

  const isPortalUrl = (portalAdminUrls, origin) => {
    if (!portalAdminUrls) return false
    return portalAdminUrls.some((url) => origin.includes(url))
  }

  const addMessageListener = (portalAdminUrls) => {
    window.addEventListener(
      PAGE_EVENTS.message,
      (event) => {
        if (!isPortalUrl(portalAdminUrls, event.origin)) return

        if(event.data.message) {
          if(event.data.message.styles) {
            var oldStyle = document.getElementById(PORTAL_STYLE_SHEET_ID)
            if(oldStyle) oldStyle.remove()
            appendStylesOverrideStyleSheet(event.data.message.styles, PORTAL_STYLE_SHEET_ID)
          }
          if(event.data.message.colors && event.data.message.colors !== EMPTY_STRING) {
            const parsedColors = JSON.parse(event.data.message.colors)
            if(parsedColors?.colors) {
              for (let color of parsedColors.colors) {
                document.documentElement.style.setProperty(color.name, color.value)
              }
            }
          }
        }
      }
    )
  }
  

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.entries().next().value) setCampain(searchParams)
  }, [searchParams])

  useEffect(() => {
    ;(async () => {
      cleanInvalidRequests()

      const settings = await loadInitialSettings()
      setGraphQLClient(
        await createGraphQLClient(settings.mobileService, setToken)
      )

      updateSrpInternalRoute(settings.country)

      loadCurrencyConfig(settings.currencyConfig)

      initializeTagManager(settings.webappGtmId)

      const permissionResponse = await requestPermission()
      setTrackingTransparency(
        permissionResponse === TRACKING_TRANSPARENCY_STATUS.authorized
      )

      addMessageListener(settings.portalAdminUrls)
    })()
  }, [])

  if (!graphQLClient) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{t('webTitle')}</title>
        <body className={modalOpen} />
      </Helmet>
      <Toaster position="top-right" />
      <ApolloProvider client={graphQLClient}>
        <CartProvider>
          <AssetLoader />
          <Suspense fallback={null}>
            <LanguageModal />
          </Suspense>
          <OneTrustButton trackingTransparency={trackingTransparency} />
          <InteractionStudioSalesforce />
          <ScrollToTop />
          <AppRoutes />
          <FirebaseMessagingProvider />
        </CartProvider>
      </ApolloProvider>
    </>
  )
}

export default App
