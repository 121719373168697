import { trackingLiterals } from '../setup/tracking/trackingLiterals'
import {
  getUser,
  getCampaign,
  setEComerseItem,
  getPageFixParams,
  trackEvent,
  getGaClient,
  buildCommerceItem,
} from '../setup/tracking/trackingFunctions'
import { getLastBillStatus } from '../util/billingHelper'
import { VALUES } from '../util/constants'

export const tagManagerArgsHome = (currentSubscriberData, vehicles) => {
  if (!vehicles || !vehicles.length) {
    return
  }
  const ecommerceArray = {
    items: [],
  }

  vehicles.forEach((vehicle, index) => {
    ecommerceArray.items.push(
      buildCommerceItem({
        vehicle,
        index,
        list_id: trackingLiterals.ecommerce.itemListId,
      })
    )
  })
  
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.view_item_list,
      page: {
        category: trackingLiterals.page.category.carlist,
        type: trackingLiterals.page.type.list,
        name: trackingLiterals.page.name.carlist,
        ...getPageFixParams(),
      },

      user: getUser(currentSubscriberData),

      campaign: getCampaign(),

      ecommerce: ecommerceArray,
    },
  }

  trackEvent(trackValues)
}

export const tagManagerCarListSelect = (
  currentSubscriberData,
  vehicle,
  id,
  index
) => {
  const ecommerceItem = {
    items: [buildCommerceItem({ vehicle, index, list_id: id })],
  }
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.select_item,
      page: {
        category: trackingLiterals.page.category.carlist,
        type: trackingLiterals.page.type.list,
        name: trackingLiterals.page.name.carlist,
        ...getPageFixParams(),
      },

      user: getUser(currentSubscriberData),

      campaign: getCampaign(),

      ecommerce: ecommerceItem,
    },
  }

  setEComerseItem(ecommerceItem)
  trackEvent(trackValues)
}

export const tagManagerMapFilterCloseButton = (subscriberData, bills) => {
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.page_event,
      event_data: {
        category: trackingLiterals.events.categories.interaction,
        action: trackingLiterals.events.actions.mapFilter,
        label: trackingLiterals.events.actions.close,
        ga_client: getGaClient(),
        country: getPageFixParams().country,
        subscription: subscriberData?.currentSubscriber?.subscription?.status,
        payment_status: getLastBillStatus(bills),
        account_status: subscriberData?.currentSubscriber?.status,
      },
    },
  }
  trackEvent(trackValues)
}

export const tagManagerMapLocationSelected = (label, subscriberData, bills) => {
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.page_event,
      event_data: {
        category: trackingLiterals.events.categories.interaction,
        action: trackingLiterals.events.actions.mapFilter,
        label: `${trackingLiterals.events.actions.openArea} ${VALUES.dash} ${label}`,
        ga_client: getGaClient(),
        country: getPageFixParams().country,
        subscription: subscriberData?.currentSubscriber?.subscription?.status,
        payment_status: getLastBillStatus(bills),
        account_status: subscriberData?.currentSubscriber?.status,
      },
    },
  }
  trackEvent(trackValues)
}

export const tagManagerMapDelaerSelected = (label, subscriberData, bills) => {
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.page_event,
      event_data: {
        category: trackingLiterals.events.categories.interaction,
        action: trackingLiterals.events.actions.mapFilter,
        label: `${trackingLiterals.events.actions.dealerName} ${VALUES.dash} ${label}`,
        ga_client: getGaClient(),
        country: getPageFixParams().country,
        subscription: subscriberData?.currentSubscriber?.subscription?.status,
        payment_status: getLastBillStatus(bills),
        account_status: subscriberData?.currentSubscriber?.status,
      },
    },
  }
  trackEvent(trackValues)
}

export const tagManagerMapFilterByDealer = (label, subscriberData, bills) => {
  const trackValues = {
    dataLayer: {
      event: trackingLiterals.events.page_event,
      event_data: {
        category: trackingLiterals.events.categories.interaction,
        action: trackingLiterals.events.actions.mapFilter,
        label: `${trackingLiterals.events.actions.conversion} ${VALUES.dash} ${trackingLiterals.events.actions.filterBy} ${label}`,
        ga_client: getGaClient(),
        country: getPageFixParams().country,
        subscription: subscriberData?.currentSubscriber?.subscription?.status,
        payment_status: getLastBillStatus(bills),
        account_status: subscriberData?.currentSubscriber?.status,
      },
    },
  }
  trackEvent(trackValues)
}
