import { useParams } from 'react-router'
import { getDefaultCountryCode } from '../util/countryHelper'

export default function useBuildUrl() {
  const { lang, countryCode } = useParams()
  const navigateCountryCode = countryCode || getDefaultCountryCode()

  return (internalPath) => {
    return `/${navigateCountryCode}/${lang}${internalPath}`
  }
}
