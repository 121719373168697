import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SvgFromString from './SvgFromString'
import { cacheOrFetchRequest } from '../../../util/fetchRequestWithCache'
import useAppState from '../../../hooks/useAppState'
import useWhiteLabels from '../../../hooks/useWhiteLabels'
import { LOCAL_BUCKET_URL, ASSET_TYPES } from '../../../util/constants'
import { ReactComponent as IconBrokenImage } from '../../../assets/icons/fi-rr-broken-image.svg'
import { buildAssetUrl, getAssetSrc } from '../bucketAssetHelper'

BucketSvgAsset.propTypes = {
  src: PropTypes.string,
  altSrc: PropTypes.string,
  assetType: PropTypes.oneOf(Object.values(ASSET_TYPES)),
  className: PropTypes.string,
}

export default function BucketSvgAsset({
  src,
  altSrc,
  assetType,
  className,
  ...props
}) {
  const { assets, fallbackAssets } = useAppState()
  const [svgAsset, setSvgAsset] = useState()
  const [unableToLoadAsset, setUnableToLoadAsset] = useState(false)
  const [styles, setStyles] = useState({})

  const whitelabels = useWhiteLabels()

  useEffect(() => {
    const fetchSvgAssetOrFallback = async (assetUrl) => {
      let svgResponse

      try {
        svgResponse = await cacheOrFetchRequest(assetUrl)
      } catch (error) {
        const localAssetUrl = buildAssetUrl(
          fallbackAssets[assetType],
          src,
          LOCAL_BUCKET_URL
        )

        if (assetUrl !== localAssetUrl) {
          try {
            svgResponse = await cacheOrFetchRequest(localAssetUrl)
          } catch (error) {
            setUnableToLoadAsset(true)
          }
        }
      }

      return svgResponse
    }

    const loadSvgAsset = async () => {
      const { url: assetUrl, ...props } = getAssetSrc({
        assets,
        fallbackAssets,
        assetName: src,
        altAssetName: altSrc,
        bucketUrl: whitelabels.whitelabelsUrl,
        assetType,
      })

      if (assetUrl) {
        const svgAsset = await fetchSvgAssetOrFallback(assetUrl)

        setSvgAsset(svgAsset)
      } else {
        setUnableToLoadAsset(true)
      }

      if (props.value && props.value.style) {
        setStyles(props.value.style)
      }
    }

    if (whitelabels && assets && fallbackAssets) {
      loadSvgAsset()
    }
  }, [assets, fallbackAssets, whitelabels])
  props = { ...props, ...styles }
  return (
    <>
      <SvgFromString svgString={svgAsset} className={className} {...props} />

      {unableToLoadAsset && (
        <div {...props}>
          <IconBrokenImage className={className} />
        </div>
      )}
    </>
  )
}
