import useCustomNavigate from '../../hooks/useCustomNavigate'
import BucketSvgIcon from '../../components/BucketAsset/BucketSvgAsset/BucketSvgIcon'
import { useSafeAreaMobile } from '../../hooks/useSafeAreaMobile'

export default function TitleLayout({
  backTo,
  flatBg,
  redirectUrl,
  title,
  children,
  disableBackTo = true,
}) {
  const { statusBarHeight } = useSafeAreaMobile()
  const navigate = useCustomNavigate()

  return (
    <div className={`min-h-screen ${flatBg ? 'bg-surface-flat' : ''}`}>
      <div style={{ paddingTop: `${statusBarHeight}px` }}>
        <nav className="h-24 nav-bg">
          <div className="max-w-7xl sm:px-6 lg:px-8 mx-auto h-24 flex items-center justify-items-center">
            {!disableBackTo ? null : (
              <a
                onClick={() => {
                  if (backTo && !redirectUrl) {
                    navigate(backTo)
                    return
                  }
                  if (redirectUrl) {
                    return (window.location.href = redirectUrl)
                  }
                  navigate(-1)
                }}
                className="cursor-pointer pt-1"
              >
                <BucketSvgIcon
                  src="fi-rr-arrow-back"
                  className="text-secondary"
                />
              </a>
            )}
            <h1 className="text-secondary font-semibold text-3xl flex-1 text-center pr-8">
              {title}
            </h1>
          </div>
        </nav>
        <main>{children}</main>
      </div>
    </div>
  )
}
