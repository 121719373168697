import { EMPTY_PNG, LOCAL_BUCKET_URL, ASSET_TYPES } from '../../util/constants'
import { useState, useEffect } from 'react'
import { buildAssetUrl } from './bucketAssetHelper'
import { getAssetSrc } from './bucketAssetHelper'
import useAppState from '../../hooks/useAppState'
import useWhiteLabels from '../../hooks/useWhiteLabels'

export default function BucketImg({ src, alt, ...props }) {
  const { assets, fallbackAssets } = useAppState()
  const [image, setImage] = useState(EMPTY_PNG)
  const [styles, setStyles] = useState({})

  const whitelabels = useWhiteLabels()

  useEffect(() => {
    const loadImageAsset = () => {
      const { url: imageAssetUrl, ...props } = getAssetSrc({
        assets,
        fallbackAssets,
        assetName: src,
        bucketUrl: whitelabels.whitelabelsUrl,
        assetType: ASSET_TYPES.image,
      })

      if (imageAssetUrl) {
        setImage(imageAssetUrl)
      }
      if (props.value && props.value.style) {
        setStyles(props.value.style)
      }
    }

    if (whitelabels && assets && fallbackAssets) {
      loadImageAsset()
    }
  }, [assets, fallbackAssets, whitelabels])

  const onError = (event) => {
    const localAssetUrl = buildAssetUrl(
      fallbackAssets[ASSET_TYPES.image],
      src,
      LOCAL_BUCKET_URL
    )

    if (event.target.src !== localAssetUrl) event.target.src = localAssetUrl
  }

  return (
    <img
      src={image}
      alt={alt}
      onError={onError}
      style={styles}
      width={styles.width}
      height={styles.height}
      {...props}
      loading="lazy"
    />
  )
}
