import { SafeArea } from 'capacitor-plugin-safe-area'
import { useEffect, useState } from 'react'
import { isNativePlatform } from '../util/native/nativeUtils'

export function useSafeAreaMobile() {
  const [statusBarHeight, setStatusBarHeight] = useState(0)

  useEffect(() => {
    if (isNativePlatform()) {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        setStatusBarHeight(statusBarHeight)
      })
    }
  }, [])

  if (!isNativePlatform()) {
    return { statusBarHeight: 0 }
  }

  return { statusBarHeight }
}
