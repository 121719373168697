import { useNavigate } from 'react-router-dom'
import { setShowOnboardingCarousel } from './helpers'
import { useEffect, useState } from 'react'
import OnboardingCarouselControls from './components/OnboardingCarouselControls'
import OnboardingCarouselWrapper from './components/OnboardingCarouselWrapper'
import settings from '../../settings'
import { SplashScreen } from '@capacitor/splash-screen'
import { isNativePlatform } from '../../util/native/nativeUtils'
import {
  CLOSE_CAROUSEL_OPTIONS,
  LENGTH_EMPTY,
  LENGTH_ONE_ELEMENT,
  VALUE_ZERO,
} from '../../util/constants'
import {
  tagManagerSwipeCarousel,
  tagManagerSwipeCarouselClose,
} from '../../tracking/trackingCarouselPage'
import useSubscriber from '../../hooks/useSubscriber'
import useBillingHistory from '../BillsPage/components/useBillingHistory'

export default function OnboardingCarrousel() {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const { data: subscriberData } = useSubscriber()
  const { data: dataBillingHistory } = useBillingHistory()
  const bills =
    dataBillingHistory?.currentSubscriber?.billingHistory?.billResults?.bills

  const carouselItemsList = settings.carouselSlides

  const closeCarousel = (type = CLOSE_CAROUSEL_OPTIONS.default) => {
    setShowOnboardingCarousel()
    navigate(0)

    if (type === CLOSE_CAROUSEL_OPTIONS.fromCta) {
      tagManagerSwipeCarouselClose(subscriberData, bills)
      return
    }

    tagManagerSwipeCarouselClose(subscriberData, bills)
  }

  useEffect(() => {
    if (carouselItemsList?.length && currentPage < carouselItemsList.length) {
      tagManagerSwipeCarousel(currentPage + 1, subscriberData, bills)
    }
  }, [currentPage])

  useEffect(() => {
    if (isNativePlatform() && carouselItemsList.length > LENGTH_EMPTY) {
      SplashScreen.hide()
    }
  }, [carouselItemsList])

  if (!carouselItemsList || carouselItemsList.length < LENGTH_ONE_ELEMENT) {
    closeCarousel()
    return <></>
  }

  const goToPage = (index) => {
    if (
      currentPage === VALUE_ZERO &&
      currentPage === carouselItemsList.length
    ) {
      return
    }
    setCurrentPage(index)
  }

  return (
    <div className="w-full relative h-screen">
      <OnboardingCarouselControls
        goToPage={goToPage}
        closeCarousel={closeCarousel}
        carouselItemsList={carouselItemsList}
        currentPage={currentPage}
      />
      <OnboardingCarouselWrapper
        carouselItemsList={carouselItemsList}
        closeCarousel={closeCarousel}
        currentPage={currentPage}
      />
    </div>
  )
}
