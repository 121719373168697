import PropTypes from 'prop-types'
import classNames from 'classnames'
import { point, distance } from '@turf/turf'
import { formatCurrencyInCents, formatDistance } from '../../../util/format'
import { VehicleUtilities } from 'f1-utils'
import { useTranslation } from 'react-i18next'
import ProgressiveImg from '../../../components/ProgressiveImg/ProgressiveImg'
import BucketImg from '../../../components/BucketAsset/BucketImg'
import BucketSvgIcon from '../../../components/BucketAsset/BucketSvgAsset/BucketSvgIcon'
import {
  isCO2EmissionsDataEnabled,
  isZeroOrGreater,
} from '../../../util/generalValidations'
import {
  AUTOMATION_IDS,
  FUEL_TYPES,
  HOURS_DAY,
  SETUP_DELAY_LIMIT,
  VALUES,
} from '../../../util/constants'
import { useEffect, useState } from 'react'
import useIsMobile from '../../../hooks/useIsMobile'

function Item(props) {
  const { icon, text } = props

  return (
    <div className="flex flex-row items-center text-gray-light">
      <span className="flex flex-none w-6 h-6 mr-2 items-center justify-center">
        <BucketSvgIcon src={icon} />
      </span>
      <div className="flex-grow">
        <p className="text-sm">{text}</p>
      </div>
    </div>
  )
}

function getDistanceToDealer(originOf, destinationOf) {
  let result

  if (originOf && destinationOf) {
    // point is a long/lat format
    const origin = point([originOf.longitude, originOf.latitude])
    const destination = point([destinationOf.longitude, destinationOf.latitude])
    let options = { units: 'miles' }
    if (originOf.unit && originOf.unit !== 'MI') {
      options = { units: 'kilometers' }
    }
    result = Math.floor(distance(origin, destination, options))
  }

  return result
}

function CO2InformationLine(props) {
  const { text, value } = props

  return (
    value && (
      <div className="flex justify-between">
        <p>{`${text}: ${value}`}</p>
      </div>
    )
  )
}

function getWhenAvailableText(setupDelay, translationFunction) {
  let text

  if (!setupDelay) {
    text = translationFunction('availableNow')
  } else if (setupDelay <= SETUP_DELAY_LIMIT) {
    text = translationFunction('hoursWait', { hours: setupDelay * HOURS_DAY })
  } else {
    text = translationFunction('daysWait', { days: setupDelay })
  }

  return text
}

// TODO: USE VEHICLE, PRICE PROPS
export default function VehicleCard({
  vehicle,
  price,
  showFromPriceText,
  hideExtras,
  hideSortDetails,
  hideEmissions,
  excludeCents = false,
  originOf,
  className,
  forceVerticalView,
  vehicleImageClassName,
  showCanceled,
  showNotAvailable,
  documentationApprovedCondition,
  priceInfo,
}) {
  const { t } = useTranslation('ui')
  const { t: vehicleTranslation } = useTranslation('vehicle')
  const { t: uiTranslation } = useTranslation('ui')
  const isMobile = useIsMobile()

  const { pricing } = vehicle
  const [isWeeklyTerm, setIsWeeklyTerm] = useState(false)

  useEffect(() => {
    if (pricing)
      setIsWeeklyTerm(VehicleUtilities.isWeeklyDuration(pricing[0].duration))
  }, [pricing])

  if (!vehicle) {
    // TODO: LOADING
    return null
  }

  // TODO: REMOVE fallback data
  const title = vehicle
    ? `${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`
    : VALUES.empty
  const trim = vehicle?.trim

  const { setupDelay } = vehicle

  let destinationOf
  if (vehicle.location?.longitude && vehicle.location?.latitude) {
    const { longitude, latitude } = vehicle?.location
    destinationOf = { longitude, latitude }
  }

  const distanceToDealer = getDistanceToDealer(originOf, destinationOf)

  const whenAvailable = getWhenAvailableText(setupDelay, t)

  const co2EmissionsDataEnabled = isCO2EmissionsDataEnabled()
  const isElectric = vehicle?.fuelType === FUEL_TYPES.ELECTRIC

  const vehicleImageClassname = classNames(
    'object-cover rounded-t-lg w-full h-44 min-h-full'
  )

  const exteriorColor = vehicle?.exteriorColorName
  const interiorColor = vehicle?.interiorColorName

  const getTitleStyles = () => {
    let baseStyles = `text-lg font-semibold line-clamp-2 mb-2 `
    if (showNotAvailable) {
      baseStyles += `text-gray`
    } else {
      baseStyles += `text-secondary`
    }
    return baseStyles
  }

  const getPriceStyles = () => {
    let baseStyles = `text-2xl whitespace-nowrap srp-vehicle-card-price `
    if (showNotAvailable) {
      baseStyles += `text-gray`
    } else {
      baseStyles += `text-secondary`
    }
    return baseStyles
  }

  const getVehicleTitle = () => {
    if (priceInfo) {
      return (
        <div className="flex justify-between">
          <div>
            <h2
              dataTestId={AUTOMATION_IDS.availableVehicleTitle}
              className={getTitleStyles()}
            >
              {title}
            </h2>
          </div>
          <div className="flex flex-col text-lg text-secondary text-right">
            {priceInfo.price}
            {priceInfo.month}
          </div>
        </div>
      )
    }

    return (
      <h2
        dataTestId={AUTOMATION_IDS.availableVehicleTitle}
        className={getTitleStyles()}
      >
        {title}
      </h2>
    )
  }

  const VehicleProgressiveImg = () => {
    if (!vehicle?.featureImage?.url?.length) {
      return (
        <BucketImg
          className={vehicleImageClassname}
          src="vehicle_default_large"
          alt="featured"
        />
      )
    }
    if (isMobile) {
      return (
        <img
          className={vehicleImageClassname}
          src={vehicle?.featureImage?.url}
          alt="featured"
        />
      )
    }
    return (
      <ProgressiveImg
        className={vehicleImageClassname}
        src={vehicle?.featureImage?.url}
        placeholderSrc={vehicle?.featureImage?.thumbUrl}
        alt="featured"
      />
    )
  }

  return (
    <div
      className={classNames(
        'bg-white flex',
        forceVerticalView && 'flex-col',
        className
      )}
    >
      <div
        className={classNames('flex-shrink-0 relative', vehicleImageClassName)}
      >
        <VehicleProgressiveImg />
        {showCanceled && (
          <div className="absolute top-4 left-4 bg-red-error rounded-lg px-3 py-1">
            <span className="text-white">{uiTranslation('canceled')}</span>
          </div>
        )}
        {documentationApprovedCondition && (
          <div className="absolute top-4 left-4 bg-red-error rounded-lg px-3 py-1">
            <span className="text-white uppercase">
              {uiTranslation('underReview')}
            </span>
          </div>
        )}
      </div>

      <div className="flex-grow flex flex-col justify-between p-4 gap-y-3 overflow-hidden">
        <div>
          {showNotAvailable && (
            <div className=" top-4 left-4 bg-white rounded-full px-3 py-1 mb-2">
              <span className="text-red-error text-xs">
                {uiTranslation('noLongerAvailable')}
              </span>
            </div>
          )}
          {!hideExtras && !showNotAvailable && (
            <>
              <div className={`flex gap-2 mb-1 w-full srp-available-bar`}>
                {isZeroOrGreater(distanceToDealer) && (
                  <div className="flex flex-none gap-1 items-center bg-gray-light-2 rounded-l-full rounded-r-full px-2 py-1 text-gray max-w-max">
                    <div className="text-sm">
                      <BucketSvgIcon
                        src="fi-rr-location-alt"
                        className="h-4 w-4"
                      />
                    </div>
                    <p className="text-xs">
                      {formatDistance(distanceToDealer)}
                    </p>
                  </div>
                )}
                <div className="flex flex-none gap-1 items-center bg-gray-light-2 rounded-l-full rounded-r-full px-2 py-1 text-gray max-w-max">
                  <div className="text-sm w-4">
                    <BucketSvgIcon src="fi-rr-quarter-to" className="h-4 w-4" />
                  </div>
                  <p className="text-xs">{whenAvailable}</p>
                </div>
              </div>
            </>
          )}
          {getVehicleTitle()}
          <div className="text-gray">
            {trim && (
              <p className="truncate whitespace-normal  line-clamp-2">
                {vehicleTranslation('trim') + ': ' + trim}
              </p>
            )}
            {exteriorColor && (
              <p className="line-clamp-1">
                {vehicleTranslation('exteriorColor') + ': ' + exteriorColor}
              </p>
            )}
            {interiorColor && (
              <p className="line-clamp-1">
                {vehicleTranslation('interiorColor') + ': ' + interiorColor}
              </p>
            )}
          </div>

          {!hideSortDetails && (
            <div className="flex gap-3 mt-4">
              {vehicle?.fuelType ? (
                <div className="flex flex-none flex-col gap-1">
                  <Item
                    icon="fi-rr-fuel"
                    text={t(`fuelTypes:${vehicle.fuelType.toLowerCase()}`)}
                  />
                </div>
              ) : null}
              {vehicle?.transmission ? (
                <div className="flex flex-none flex-col gap-1">
                  <Item icon="fi-rr-transmission" text={vehicle.transmission} />
                </div>
              ) : null}
            </div>
          )}
        </div>

        {!hideEmissions && co2EmissionsDataEnabled && (
          <div className="text-gray text-xs">
            {!isElectric && (
              <CO2InformationLine
                text={t('combinedPetrolConsumption')}
                value={vehicle?.combinedPetrolConsumption}
              />
            )}

            <CO2InformationLine
              text={t('electricityConsumption')}
              value={vehicle?.electricityConsumption}
            />

            {!isElectric && (
              <CO2InformationLine
                text={t('co2emissionsValue')}
                value={vehicle?.co2Emission}
              />
            )}
          </div>
        )}

        {price ? (
          <div className="flex items-end justify-end">
            {showFromPriceText && (
              <p className="text-gray flex-grow srp-vehicle-card-from">
                {t('from')}
              </p>
            )}

            <p className={getPriceStyles()}>
              {formatCurrencyInCents(price, { excludeCents: excludeCents })}
            </p>
            <p className="text-secondary srp-vehicle-card-term">
              {isWeeklyTerm
                ? t('srpVehicleCardWeek')
                : t('srpVehicleCardMonth')}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

VehicleCard.propTypes = {
  border: PropTypes.string,
  hideExtras: PropTypes.bool,
  originOf: PropTypes.shape({}),
  price: PropTypes.number,
  vehicle: PropTypes.shape({}),
  showCanceled: PropTypes.bool,
  showNotAvailable: PropTypes.bool,
}
