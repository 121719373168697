import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { parseFavouritesKey } from './useFavourites'
import useAppState from './useAppState'
import { useEffect } from 'react'

export const FAVOURITES_VEHICLE_QUERY = gql`
  query getFavouriteVehicles($input: GetFavoriteVehiclesInput!) {
    getFavouriteVehicles(input: $input) {
      vehicleIds
      vehicles {
        id
        year
        make
        model
        trim
        description
        status
        rideshareEligible
        co2Emission
        co2EfficiencyClass
        electricityConsumption
        combinedPetrolConsumption
        electricRange
        pricing {
          value
          duration
          durationUnit
          deductible {
            description
            amount
          }
          subscriptionTerms {
            term
            additionalPrice
            discountPercentage
            hidden
            displayPrice
          }
          mileageTerms {
            mileage
            additionalPrice
            isDefaultAdditionalRate
            additionalInterval
            additionalRate
            hidden
            displayPrice
          }
          bestTerm {
            term
            discountPercentage
            additionalPrice
          }
          bestPrice
          displayPrice
        }
        location {
          id
          name
          address {
            address1
            address2
            city
            state
            country
            zip
          }
          latitude
          longitude
        }
        featureImage {
          url
        }
        images {
          url
        }
        priceBreakdown {
          percentages {
            name
            value
          }
        }
        mileagePricing {
          included
          unlimited
          additionalRate
          additionalInterval
        }
        exteriorColorName
        exteriorColorHex
        interiorColorName
        interiorColorHex
        odometer
        odometerUnit
        fuelType
        transmission
        bodyStyle
        driveType
        horsepower
        dealer {
          id
          name
        }
        engineSize
        engineKw
        gearsNumber
        priceBrutto
        equipment {
          description
        }
        mpgMixed
        seats
        doors
        setupDelay
        deposit
        disabled
      }
    }
  }
`

export function useFavouriteVehicles() {
  const { isLoggedIn } = useAppState()

  const {
    data: serverResult,
    loading,
    refetch,
  } = useQuery(FAVOURITES_VEHICLE_QUERY, {
    variables: {
      input: { vehicleIds: !isLoggedIn ? parseFavouritesKey() : [] },
    },
  })

  return {
    vehicles: serverResult?.getFavouriteVehicles?.vehicles || [],
    loading,
    loadVehicles: refetch,
  }
}

export default function useFavouriteVehicleIds() {
  const { isLoggedIn } = useAppState()

  const [getfavourites, { data: serverResult }] = useLazyQuery(
    FAVOURITES_VEHICLE_QUERY,
    {
      variables: {
        input: { vehicleIds: [] },
      },
    }
  )

  useEffect(() => {
    if (isLoggedIn) {
      getfavourites()
    }
  }, [isLoggedIn])

  if (!isLoggedIn) {
    return {
      favouriteVehicleIds: parseFavouritesKey(),
      refreshFavourites: getfavourites,
    }
  }

  if (serverResult?.getFavouriteVehicles?.vehicleIds?.length) {
    return {
      favouriteVehicleIds: serverResult?.getFavouriteVehicles?.vehicleIds,
      refreshFavourites: getfavourites,
    }
  }

  return { favouriteVehicleIds: [], refreshFavourites: getfavourites }
}
