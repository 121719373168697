const VEHICLE_ID_PARAM = ':vehicleId'
const VEHICLE_MAKE_PARAM = ':vehicleMake'
const VEHICLE_MODEL_PARAM = ':vehicleModel'
const BILL_ID_PARAM = ':billId'
const RELATIVE_PAYMENT_CARD = '/payment-card'

const getBillDetailsRoute = (billId) => {
  return INTERNAL_ROUTES.BILL_DETAILS.replace(BILL_ID_PARAM, billId)
}

const getVehicleDetailsRoute = (vehicle) => {
  return INTERNAL_ROUTES.VDP.replace(VEHICLE_ID_PARAM, vehicle?.id)
    .replace(VEHICLE_MAKE_PARAM, encodeURIComponent(vehicle?.make))
    .replace(VEHICLE_MODEL_PARAM, encodeURIComponent(vehicle?.model))
}

const srpPathByCountry = {
  default: '/cars',
  es: '/coches-por-suscripcion',
  de: '/autos',
}

const getSrpPathByCountry = (countryCode) => {
  const srpUrl =
    srpPathByCountry[countryCode?.toLowerCase()] || srpPathByCountry.default

  return srpUrl
}

const updateSrpInternalRoute = (newCountryCode) => {
  INTERNAL_ROUTES.SRP = getSrpPathByCountry(newCountryCode)
}

const INTERNAL_ROUTES = {
  CONSENT_CHECKS: '/consents',
  DASHBOARD: '/',
  LOGIN: '/login',
  SSO_LOGIN: '/login-sso',
  FILTERS: '/filters',
  RESET_PASSWORD: '/reset-password',
  REGISTER: '/register',
  VALIDATE_EMAIL: '/validate-email',
  FAVOURITES: '/favourites',
  SRP: srpPathByCountry.default,
  VDP: `/vdp/${VEHICLE_MAKE_PARAM}/${VEHICLE_MODEL_PARAM}/${VEHICLE_ID_PARAM}`,
  CHECKOUT: '/checkout',
  DOCUMENT_UPLOAD: '/document-upload',
  CHECKOUT_SSO: '/checkout-sso',
  CHECKOUT_RENEW: '/checkout-renew',
  CHECKOUT_PAYMENT: '/checkout/payment',
  CHECKOUT_PAYMENT_CARD: `/checkout/payment${RELATIVE_PAYMENT_CARD}`,
  CHECKOUT_CONFIRM: '/checkout/confirm',
  CHECKOUT_VALIDATE_PHONE: '/checkout/validate-phone',
  PAYMENT_SCA: '/payment-sca',
  EDIT_PROFILE: '/edit-profile',
  MY_SUBSCRIPTION: '/my-subscription',
  MY_SUBSCRIPTIONS: '/my-subscriptions',
  MY_COUPONS: '/my-coupons',
  MY_ACCOUNT: '/my-account',
  MY_COMUNICATION_PREFERENCES: '/preference-centre',
  BILLS: '/bills',
  BILL_DETAILS: `/bills/${BILL_ID_PARAM}`,
  BILLS_PAYMENT_CARD: `/bills${RELATIVE_PAYMENT_CARD}`,
  CONFIRM_IDENTITY: '/confirm-identity',
  BANK_ID_RETURN: '/confirm-identity/bank-id-return',
  STRIPE_CHECKOUT_RETURN: '/stripe-checkout-return',
  RELATIVE_PAYMENT_CARD: RELATIVE_PAYMENT_CARD,
  UNDER_REVIEW: '/under-review',
  ACCESS_DENIED: '/access-denied',
  EXPERIAN_CHECK: '/experian-check',
  USER_CREDIT_DOCUMENT: '/user-credit-document',
  ACCEPT_NEW_REGISTER_CHECKS: '/accept-checks',
  INDEX: '/',
}

export {
  INTERNAL_ROUTES,
  srpPathByCountry,
  getSrpPathByCountry,
  getVehicleDetailsRoute,
  getBillDetailsRoute,
  updateSrpInternalRoute,
}
