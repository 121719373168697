import { useEffect, useState } from 'react'
import BucketSvgIcon from '../../../components/BucketAsset/BucketSvgAsset/BucketSvgIcon'
import useDeleteFavouriteVehicleMutation from '../../../hooks/useDeleteFavouriteVehicleMutation'
import useSaveFavouriteVehicleMutation from '../../../hooks/useSaveFavouriteVehicleMutation'
import useIsMobile from '../../../hooks/useIsMobile'

export default function VehicleFavourite({
  id,
  onDelete,
  favouriteVehicleIds,
  disabled,
  className,
}) {
  const isMobile = useIsMobile()
  const { saveToFavourites } = useSaveFavouriteVehicleMutation()
  const { deleteFromFavourites } = useDeleteFavouriteVehicleMutation()
  const [favourite, setfavourite] = useState(false)

  useEffect(() => {
    if (favouriteVehicleIds?.includes(id)) {
      setfavourite(true)
    }
  }, [favouriteVehicleIds])

  const switchFavourite = () => {
    setfavourite((oldFavourite) => {
      if (!oldFavourite) {
        saveToFavourites(id)
      } else {
        deleteFromFavourites(id).then(() => {
          if (onDelete) {
            onDelete(id)
          }
        })
      }

      return !oldFavourite
    })
  }

  if (disabled) {
    return (
      <div
        className={
          className?.length
            ? className
            : isMobile
            ? 'cursor-pointer absolute right-0 -top-4 bg-white rounded-full p-2 z-10'
            : 'cursor-pointer absolute right-0 bg-white rounded-full p-2 z-10'
        }
      >
        <>
          <BucketSvgIcon
            src="fi-rr-trash"
            className="text-black h-6 w-6"
            onClick={switchFavourite}
          />
        </>
      </div>
    )
  }
  return (
    <div
      className={
        className?.length
          ? className
          : isMobile
          ? 'cursor-pointer absolute right-0 -top-4 bg-white rounded-full p-2 z-10'
          : 'cursor-pointer absolute right-0 bg-white rounded-full p-2 z-10'
      }
    >
      {!favourite && (
        <>
          <BucketSvgIcon
            src="heart-outline"
            className="text-black h-6 w-6"
            onClick={switchFavourite}
          />
        </>
      )}
      {favourite && (
        <>
          <BucketSvgIcon
            src="heart-red"
            className="text-black h-6 w-6"
            onClick={switchFavourite}
          />
        </>
      )}
    </div>
  )
}
