import {
  AppTrackingTransparency
} from 'capacitor-plugin-app-tracking-transparency';
import { getPlatform } from './native/nativeUtils';
import { PLATFORMS, TRACKING_TRANSPARENCY_STATUS } from './constants';

function checkIos() {
  return getPlatform() === PLATFORMS.ios
}
export async function getStatus() {
  if (checkIos()) {
    const response = await AppTrackingTransparency.getStatus()
    return response.status
  } else {
    return TRACKING_TRANSPARENCY_STATUS.authorized
  }
}

export async function requestPermission() {
  if (checkIos()) {
    const response = await AppTrackingTransparency.requestPermission()
    return response.status
  } else {
    return TRACKING_TRANSPARENCY_STATUS.authorized
  }
} 