import { createContext, useContext, useEffect, useState } from 'react'
import useSubscriptionEstimate from '../pages/CheckoutPage/useSubscriptionEstimate'
import { LANGUAGE_CONFIG } from '../util/languagesManager'
import {
  updateLanguageConfig,
  updateLanguageInHTML,
} from '../util/languagesManager'
import settings from '../settings'

import { I18_MAPPING } from 'f1-utils/lib/languages'

const getSubscriptionChecks = () => {
  let checkItemsByColumn = []
  for (const key in settings?.subscriptionChecks) {
    const item = {
      key,
      value: false,
    }
    checkItemsByColumn.push(item)
  }
  return checkItemsByColumn
}

const CartContext = createContext()

export function CartProvider(props) {
  const [duration, setDuration] = useState()
  const [durationUnit, setDurationUnit] = useState()
  const [subscriptionTerm, setSubscriptionTerm] = useState()
  const [mileageTerm, setMileageTerm] = useState()
  const [vehicleId, setVehicleId] = useState()
  const [pickupDate, setPickupDate] = useState()
  const [endDate, setEndDate] = useState()
  const [subscriptionId, setSubscriptionId] = useState()
  const [loaded, setLoaded] = useState(false)
  const [languagesLoaded, setLanguagesLoaded] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState()
  const [promoCode, setPromoCode] = useState()
  const [checkoutId, setCheckoutId] = useState()
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [localStorageImageKeys, setLocalStorageImageKeys] = useState([])

  const [subscriptionChecks, setSubscriptionChecks] = useState(
    getSubscriptionChecks()
  )

  const clearSubscriptionChecks = () => {
    setSubscriptionChecks(getSubscriptionChecks())
  }

  const updateLanguages = () => {
    const availableLanguages = settings.availableLanguages?.map((lang) => ({
      ...lang,
      languageCode: I18_MAPPING[lang.languageConstant],
    }))

    if (availableLanguages) {
      updateLanguageConfig(availableLanguages)
      updateLanguageInHTML(LANGUAGE_CONFIG.defaultLanguage)
      setLanguagesLoaded(true)
    }
  }

  const clearCartSubscriptionData = () => {
    setDuration()
    setDurationUnit()
    setSubscriptionTerm()
    setMileageTerm()
    setVehicleId()
    setPickupDate()
    setEndDate()
    setSubscriptionId()
    clearSubscriptionChecks()
    setPromoCode()
  }

  useEffect(() => {
    if (loaded) {
      localStorage.setItem(
        'CART',
        JSON.stringify({
          duration,
          durationUnit,
          subscriptionTerm,
          mileageTerm,
          vehicleId,
          pickupDate,
          endDate,
          subscriptionId,
          paymentMethodId,
          promoCode,
          uploadedFiles,
          localStorageImageKeys,
          subscriptionChecks,
        })
      )
    }
  }, [
    duration,
    durationUnit,
    subscriptionTerm,
    mileageTerm,
    vehicleId,
    pickupDate,
    endDate,
    subscriptionId,
    paymentMethodId,
    loaded,
    uploadedFiles,
    localStorageImageKeys,
    subscriptionChecks,
  ])

  useEffect(() => {
    updateLanguages()

    try {
      const cartStr = localStorage.getItem('CART')

      if (cartStr) {
        const cart = JSON.parse(cartStr)
        const storedPickupDate = cart.pickupDate
          ? new Date(cart.pickupDate)
          : undefined

        setDuration(cart.duration)
        setDurationUnit(cart.durationUnit)
        setSubscriptionTerm(cart.subscriptionTerm)
        setMileageTerm(cart.mileageTerm)
        setVehicleId(cart.vehicleId)
        setPickupDate(storedPickupDate)
        setEndDate(new Date(cart.endDate))
        setSubscriptionId(cart.subscriptionId)
        setPaymentMethodId(cart.paymentMethodId)
        setPromoCode(cart.promoCode)
        setUploadedFiles(cart.uploadedFiles)
        setLocalStorageImageKeys(cart.localStorageImageKeys)
        cart.subscriptionChecks &&
          setSubscriptionChecks(cart.subscriptionChecks)
      }
    } catch (err) {
      // Continue
    }
    setLoaded(true)
  }, [])

  const {
    loading: estimateLoading,
    error: estimateError,
    data: estimateData,
    refetch: refetchEstimate,
  } = useSubscriptionEstimate({
    subscriptionId,
    subscriptionTerm,
    mileageTerm,
    pickupDate,
    vehicleId,
    duration,
    durationUnit,
    promotionalCodeId: promoCode?.id,
  })

  if (!loaded || !languagesLoaded) {
    return null
  }

  return (
    <CartContext.Provider
      value={{
        duration,
        setDuration,
        durationUnit,
        setDurationUnit,
        subscriptionTerm,
        setSubscriptionTerm,
        mileageTerm,
        setMileageTerm,
        vehicleId,
        setVehicleId,
        pickupDate,
        setPickupDate,
        endDate,
        setEndDate,
        subscriptionId,
        setSubscriptionId,
        checkoutId,
        setCheckoutId,
        estimateLoading,
        estimateError,
        estimateData,
        refetchEstimate,
        paymentMethodId,
        promoCode,
        setPromoCode,
        setPaymentMethodId,
        uploadedFiles,
        setUploadedFiles,
        localStorageImageKeys,
        setLocalStorageImageKeys,
        subscriptionChecks,
        setSubscriptionChecks,
        clearSubscriptionChecks,
        clearCartSubscriptionData,
      }}
      {...props}
    />
  )
}

export function useCart() {
  const context = useContext(CartContext)
  if (!context) {
    throw new Error('useCart must be used within a CartContextProvider')
  }

  return context
}
